import {UmEntity, UmQuery} from "../core";
import {ServiceDailyEntity} from "@/core/entity/report/daily";
import {ServiceRoutingEntity} from "@/core/entity/report/routing";
import {ServiceVoyageEntity, ServiceVoyageinfoEntity} from "@/core/entity/report/voyage";
import {ServiceWeatherEntity} from "@/core/entity/report/weather";
import {FileEntity} from "@/core/entity/file/file";



/**
 * (Entity)
 * @author syj
 * @date 2021-09-28 14:08
 */
export class ServiceReportEntity extends UmEntity {
	//服务主键
	public serviceKey?: number;
	//b_file主键
	public fileKey?: number;
	//文件
	public file?: FileEntity;
}


/**
 * (Query)
 * @author syj
 * @date 2021-09-28 14:08
 */
export class ServiceReportQuery extends UmQuery {

	//服务主键
	public serviceKey?: number;
}

export class TimelineItem{
	//类型  0：voyage 信息  1：report报告  2：deily 3:weather  4:voyageInfo 5:routing
	public type?:number;
	//时间轴上名称 voyage 为voyage   report为report  其他为时间
	public name?:string;
	//report报告
	public report: ServiceReportEntity={};
	//voyage
	public voyage?: ServiceVoyageEntity={};
	//当前状态 0新增  1修改
	public status?:number;
	//时间
	public recordedDate?:number;
	//Daily
	public daily?: ServiceDailyEntity={};
	//routing
	public routing?: ServiceRoutingEntity={};
	//voyageInfo
	public voyageInfo?: ServiceVoyageinfoEntity={};
	//weather
	public weather?: ServiceWeatherEntity={};
}
