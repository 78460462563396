/**
 * 日期格式转换
 */
export default class {
    // 默认时间格式：年-月-日 时:分:秒
    static readonly defaultTimeFormat: string = "yyyy-MM-dd HH:mm:ss";
    // 时间格式：年-月-日 时:分
    static readonly minuteFormat: string = "yyyy-MM-dd HH:mm";
    static readonly monthToMinuteFormat: string = "MM-dd HH:mm";
    static readonly yearToDay: string = "yyyy-MM-dd";
    static readonly month:string="MM";
    static readonly day:string="dd";
    // utc时区
    static readonly utcTime: string = "utcTime";
    // 当前时区
    static readonly currentTime: string = "localTime";
    static readonly hourToMinuteFormat: string = "HH:mm";
    static readonly MouthToDay: string = "MM-dd";
    static readonly monthsShort = 'Jan. Feb. Mar. Apr. May Jun. Jul. Aug. Sep. Oct. Nov. Dec.'.split(' ');
    /**
     * 前补零
     * @param num
     */
    static leadingZero(num: number): string {
        return num.toString().replace(/^(\d)$/, "0$1");
    }

    /**
     * 将指定时区的时间字符串转成时间戳
     * @param time      时间字符串
     * @param timeZone  指定时区
     */
    static parseDate(time: string, timeZone = this.currentTime): number {
        if (timeZone === this.currentTime) {
            // todo:把-变程/了，要不苹果手机只能识别yyyy/MM/dd HH:mm:ss这种格式的
            return new Date(time.replace(new RegExp("-", "g"), "/")).getTime();
        }

        return 0;
    }

    /**
     * 将时间戳转成指定时区指定格式的时间字符串
     * @param timestamp 时间戳
     * @param timeZone  时区
     * @param format    格式
     */
    static formatWithTimestamp(timestamp: number, timeZone = this.currentTime, format = this.defaultTimeFormat,isMonthEn=false): string {
        return this.formatWithDate(new Date(timestamp), timeZone, format,isMonthEn);
    }

    /**
     * 将Date转成指定时区指定格式的时间字符串
     * @param date      日期对象
     * @param timeZone  时区
     * @param format    格式
     */
    static formatWithDate(date: Date, timeZone = this.currentTime, format:string = this.defaultTimeFormat,isMonthEn=false): string {
        // 获得指定格式字符
        const cfg: Object = this.getTimeParams(date, timeZone,isMonthEn);
        // 匹配时间格式
        return format.replace(/([a-z])(\1)*/ig, (m) => {
            return (<any>cfg)[m] ;
        });
    }

    /**
     * 将Date 转成特定时区（偏离 utc 分钟数，beijing 8*60 = 480(注意此处与js中getTimezoneOffset()方法相反)）指定格式的时间字符串
     * @param date
     * @param utcTimeZoneOffset
     * @param format
     */
    static formatWithSpecificTimezone(date: Date, utcTimeZoneOffset:number, format = this.defaultTimeFormat){
        const dateNew = new Date(date);
        dateNew.setMinutes(date.getMinutes() + utcTimeZoneOffset);
        return this.formatWithDate(dateNew,this.utcTime,format);
    }

    /**
     * 获取UTC到本地时间时差  分钟
     */
    static getUtcToLocalTimeLong():number{
        return  new Date().getTimezoneOffset();
    }

    /**
     * 返回指定格式字符
     * @param date      日期
     * @param timeZone  哪个时区
     */
    private static getTimeParams(date: Date, timeZone = this.currentTime,isMonthEn=false): object {
        if (timeZone === this.utcTime) {
            return {
                yyyy: date.getUTCFullYear(),                           // 年 : 4位
                yy: date.getUTCFullYear().toString().substring(2),     // 年 : 2位
                M: isMonthEn?this.monthsShort[date.getUTCMonth()]:date.getUTCMonth() + 1,                             // 月 : 如果1位的时候不补0
                MM: isMonthEn?this.monthsShort[date.getUTCMonth()] : this.leadingZero(date.getUTCMonth() + 1),          // 月 : 如果1位的时候补0
                d: date.getUTCDate(),                                  // 日 : 如果1位的时候不补0
                dd: this.leadingZero(date.getUTCDate()),               // 日 : 如果1位的时候补0
                HH: this.leadingZero(date.getUTCHours()),              // 时: 如果1位的时候补0
                mm: this.leadingZero(date.getUTCMinutes()),            // 分: 如果1位的时候补0
                ss: this.leadingZero(date.getUTCSeconds())             // 秒: 如果1位的时候补0
            };
        } else if (timeZone === this.currentTime) {
            return {
                yyyy: date.getFullYear(),                           // 年 : 4位
                yy: date.getFullYear().toString().substring(2),     // 年 : 2位
                M: isMonthEn?this.monthsShort[date.getMonth()]:date.getMonth() + 1,                             // 月 : 如果1位的时候不补0
                MM:isMonthEn?this.monthsShort[date.getMonth()]: this.leadingZero(date.getMonth() + 1),          // 月 : 如果1位的时候补0
                d: date.getDate(),                                  // 日 : 如果1位的时候不补0
                dd: this.leadingZero(date.getDate()),               // 日 : 如果1位的时候补0
                HH: this.leadingZero(date.getHours()),              // 时: 如果1位的时候补0
                mm: this.leadingZero(date.getMinutes()),            // 分: 如果1位的时候补0
                ss: this.leadingZero(date.getSeconds())             // 秒: 如果1位的时候补0
            };
        }else{
            return {};
        }
    }



}
