<template>
  <v-form ref="form" lazy-validation>
    <v-row v-show="reportList.length>0">
      <v-col cols="12" md="12" v-for="item in reportList">
        <v-row>
          <v-col cols="12" md="5">
            {{ item.file.name }}
          </v-col>
          <v-col cols="12" md="1">
            {{ item.file.size + item.file.sizeUnit }}
          </v-col>
          <v-col cols="12" md="2">
            上传时间:
            {{ item.file ? format(item.file.operTime, "yyyy-MM-dd HH:mm:ss") : '' }}
          </v-col>
          <v-col cols="12" md="2">
            <v-btn small color="primary" tile @click="del(item.key)">{{
                $tip.button.del
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-show="reportList.length===0" justify="center">
      <span>
       暂无数据
      </span>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-file-input
            chips
            label="File input w/ chips"
            v-model="uploadFiles"
            show-size
            multiple
        ></v-file-input>
      </v-col>

      <v-col cols="12" md="12">
        <v-row justify="center">
          <v-btn :loading="saving" large color="primary" tile @click="upload">{{
              $tip.button.upload
            }}
          </v-btn>
        </v-row>

      </v-col>

    </v-row>
  </v-form>
</template>

<script lang="ts">
import Vue from "vue";
import DateFormat from "@/core/util/DateFormat";
import {ServiceReportEntity} from "@/core/entity/report/report";
import service from "@/core/service";
import {ServiceEntity} from "@/core/entity/service/service";
import globalFunc from "@/core/store/globalFunc";
import {ServiceRoutingFileEntity} from "@/core/entity/report/routing";

export default Vue.extend({
  name: "reportReport",
  props: {
    report: Object
  },
  data() {
    return {
      saving: false,
      uploadFiles: [] as File[],
      key: void 0 as unknown as number,
      reportList: [] as Array<ServiceReportEntity>,
      serviceInfo: {} as ServiceEntity,
    }
  },
  async mounted() {
    this.key = Number(this.$route.params.key);
    this.serviceInfo = await service.service.load(this.key);
    if (this.serviceInfo.vesselKey !== void 0 && this.serviceInfo.vesselKey > 10000000) {
      const aisList = await service.ais.infoBatch({mmsi: [this.serviceInfo.vesselKey]});
      if (aisList !== void 0 && aisList.length > 0) {
        this.$store.commit(globalFunc.setTabTitle, {
          path: this.$route.path,
          title: aisList[0].name + "-报告维护",
        });
      }

    }
    this.reportList = await service.report.query({serviceKey: this.key});

  },
  methods: {
    format(time: number, formatStr: string) {
      return DateFormat.formatWithTimestamp(time, DateFormat.utcTime, formatStr);
    },
    async upload() {
      this.saving = true;
      try {
        if (this.uploadFiles === void 0 || this.uploadFiles.length === 0) {
          this.$message("请选择文件!", "error");
          this.saving = false;
        } else {
          const formData = new FormData();
          for (let i = 0; i < this.uploadFiles.length; i++) {
            formData.append("files", this.uploadFiles[i], this.uploadFiles[i].name);
          }
          formData!.append("mainKey", this.key.toString());
          const fileList = await service.serviceFile.upFileBatch(formData);
          const saveFileList = [];
          for (let i = 0; i < fileList.length; i++) {
            const rfEntity = new ServiceReportEntity();
            rfEntity.serviceKey = this.key;
            rfEntity.fileKey = fileList[i].key;
            saveFileList.push(rfEntity);
          }
          await service.report.addBatch(saveFileList);
          this.reportList = await service.report.query({serviceKey: this.key})
        }
      } catch (e) {
        this.$message("保存出错", "error");
      } finally {
        this.saving = false;
        this.uploadFiles=[];
      }
    },

    async del(key: number) {
      await service.report.del(key);
      this.reportList = await service.report.query({serviceKey: this.key});
    }

  }
})
</script>

<style lang="scss">
.tableRequestDiv {
  padding-left: 200px;
  font-size: 20px;
  font-family: 'Times New Roman';
  padding-top: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tableRow1 {
  width: 450px;
  border: 1px solid #CCCCCC;
  font-weight: normal;
  height: 45px
}

.tableRow2 {
  width: 450px;
  border: 1px solid #CCCCCC;
  text-align: left;
  height: 45px;
}

.tableRow3 {
  width: 450px;
  border: 1px solid #CCCCCC;
  text-align: center;
  height: 45px;
  font-weight: bold;
}

.tableRow4 {
  width: 450px;
  text-align: left;
  height: 45px;
}


</style>
